import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { useData } from '../../../hooks/use-data';
import { useMounted } from '../../../hooks/use-mounted';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import { vehicleWorkOrdersApi } from '../../../api/goal/vehicle/vehicle-work-orders-api';
import { useApp } from '../../../hooks/use-app';
import GoalCustomDialog from '../../goal-custom-dialog';
import { useGoalTheme } from '../../../hooks/use-goal-theme';

const WorkOrderContactsNotification = ({
  companyId,
  title = '',
  open = false,
  onClose,
  onConfirm,
  handleRefresh
}) => {
  const { showError } = useApp;
  const mounted = useMounted();
  const { isMobile } = useGoalTheme();

  const [confirmationLoading, setConfirmationLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);

  const handleChangeSelect = (event, contact) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setSelectedContact(contact);
    } else {
      setSelectedContact(null);
    }
  };

  const [dataState] = useData({
    sourceApi: vehicleWorkOrdersApi.getUsersEnabledToApprove,
    apiParameter: {
      companyId: companyId
    },
    loadingMessage: 'Cargando contactos...',
    mounted
  });

  const handleConfirm = async (contact) => {
    if (contact) {
      setConfirmationLoading(true);
      const response = await onConfirm(contact);
      setConfirmationLoading(false);
      if (response) {
        onClose();
        if (handleRefresh) {
          handleRefresh();
        }
      }
    } else {
      showError('Debe selecionar contacto.');
    }
  };

  const renderContent = () => {
    if (dataState.isLoading || dataState.displayError) {
      return (
        <TableRow>
          <TableCell colSpan={2}>
            {dataState.isLoading && (
              <>
                <Skeleton height={42} />
                <Skeleton height={42} />
                <Skeleton height={42} />
              </>
            )}
            {!dataState.isLoading && dataState.displayError && (
              <Typography variant="subtitle2">Ocurrió un error al cargar los contactos.</Typography>
            )}
          </TableCell>
        </TableRow>
      );
    }

    const contacts = dataState.data || [];
    return (
      <>
        {contacts.length > 0 ? (
          contacts.map((contact) => (
            <TableRow key={contact.id}>
              <TableCell>
                <Checkbox
                  checked={selectedContact?.id === contact.id && checked}
                  onChange={(event) => handleChangeSelect(event, contact)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </TableCell>
              <TableCell>{`${contact.first_name} ${contact.last_name}`}</TableCell>
              <TableCell>{contact.email}</TableCell>
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={2}>
              <Typography variant="subtitle2">No hay contactos para el vehículo.</Typography>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  return (
    <GoalCustomDialog
      open={open}
      handleClose={onClose}
      maxWidth="md"
      title={title}
      fullScreen={isMobile}
      withoutFooter
    >
      <Box sx={{ mb: 2 }}>
        <Typography variant="subtitle1">
          Selecciona la persona encargada de la aprobación, se le enviará una notificación a su
          correo electrónico:
        </Typography>
      </Box>

      <Paper>
        <Table size="small" sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Correo electrónico</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderContent()}</TableBody>
        </Table>
      </Paper>

      <Box sx={{ pt: 2, width: '100%' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'flex-end',
            justifyContent: 'flex-end'
          }}
        >
          <Button color="secondary" variant="contained" onClick={onClose} sx={{ mr: 1 }}>
            Cancelar
          </Button>
          <LoadingButton
            color="primary"
            variant="contained"
            loading={confirmationLoading}
            onClick={() => handleConfirm(selectedContact)}
            disabled={confirmationLoading || dataState.isLoading || selectedContact === null}
          >
            Enviar
          </LoadingButton>
        </Box>
      </Box>
    </GoalCustomDialog>
  );
};

WorkOrderContactsNotification.propTypes = {
  companyId: PropTypes.number.isRequired,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  handleRefresh: PropTypes.func
};

export default WorkOrderContactsNotification;
