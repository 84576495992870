import PropTypes from 'prop-types';
import EmailIcon from '@mui/icons-material/Email';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ApprovalIcon from '@mui/icons-material/Approval';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useGoalMenu } from '../../../hooks/use-goal-menu';
import { GoalMenu } from '../../common/goal-menu';
import { GoalMenuDialog } from '../../menu/goal-menu-dialog';
import { PDFViewerdDialog } from '../../common/pdf-viewer-dialog';
import { vehicleApi } from '../../../api/goal/operations/vehicles/vehicle';
import { useApp } from '../../../hooks/use-app';
import { ConfirmationMenuItem } from '../../common/confirmation-menu-item';
import { vehicleWorkOrdersApi } from '../../../api/goal/vehicle/vehicle-work-orders-api';
import WorkOrderContactsNotification from './work-order-contacts-notification';

export const WorkOrdersMenu = ({ workOrder, handleRefresh }) => {
  const { currentCompany, showNotify, showError } = useApp();
  const [menuController] = useGoalMenu();

  const sendWorkOrderToApproval = async (contact = []) => {
    const response = await vehicleWorkOrdersApi.sendWorkOrderToApproval({
      workOrderNumber: workOrder.work_order_number,
      values: { company_id: currentCompany, user_id: contact.id }
    });

    if (response?.success) {
      showNotify(response.message || 'Órden de trabajo enviada para aprobación correctamente');
      return true;
    } else {
      showError(response.message || 'Hubo un error al enviar la órden de trabajo para aprobación');
      return false;
    }
  };

  return (
    <>
      <GoalMenu
        startIcon={<MoreVertIcon fontSize="medium" />}
        iconOnly={true}
        controller={menuController}
      >
        {workOrder?.fleet_status?.is_draft === 1 && (
          <GoalMenuDialog
            startIcon={<SendIcon />}
            Component={WorkOrderContactsNotification}
            componentProps={{
              companyId: currentCompany,
              title: 'Enviar para aprobación',
              onClose: menuController.handleClose,
              onConfirm: sendWorkOrderToApproval,
              handleRefresh: handleRefresh
            }}
            tooltip={''}
          >
            Enviar para aprobación
          </GoalMenuDialog>
        )}

        {workOrder?.fleet_status?.is_pending_approval === 1 && (
          <ConfirmationMenuItem
            label={'Aprobar'}
            confirmationMessage={`Vas a Aprobar la órden de trabajo ${workOrder.work_order_number}.`}
            startIcon={<ApprovalIcon />}
            onConfirm={async () => {
              const response = await vehicleWorkOrdersApi.approveWorkOrder({
                workOrderNumber: workOrder.work_order_number,
                values: { company_id: currentCompany }
              });
              if (response?.success) {
                showNotify(response.message || 'Órden de trabajo aprobada correctamente');
                handleRefresh();
              } else {
                showError(response.message || 'Hubo un error al aprobar la órden de trabajo');
              }
            }}
          />
        )}

        {workOrder?.fleet_status?.is_approved === 1 && (
          <ConfirmationMenuItem
            label={'Activar'}
            confirmationMessage={`Vas a Activar la órden de trabajo ${workOrder.work_order_number}.`}
            startIcon={<PowerSettingsNewIcon />}
            onConfirm={async () => {
              const response = await vehicleWorkOrdersApi.activateWorkOrder({
                workOrderNumber: workOrder.work_order_number,
                values: { company_id: currentCompany }
              });
              if (response?.success) {
                showNotify(response.message || 'Órden de trabajo activada correctamente');
                handleRefresh();
              } else {
                showError(response.message || 'Hubo un error al activar la órden de trabajo');
              }
            }}
          />
        )}

        {workOrder?.fleet_status?.is_active === 1 && (
          <ConfirmationMenuItem
            label={'Completar'}
            confirmationMessage={`Vas a Completar la órden de trabajo ${workOrder.work_order_number}.`}
            startIcon={<CheckCircleIcon />}
            onConfirm={async () => {
              const response = await vehicleWorkOrdersApi.completeWorkOrder({
                workOrderNumber: workOrder.work_order_number,
                values: { company_id: currentCompany }
              });
              if (response?.success) {
                showNotify(response.message || 'Órden de trabajo completada correctamente');
                handleRefresh();
              } else {
                showError(response.message || 'Hubo un error al completar la órden de trabajo');
              }
            }}
          />
        )}

        <ConfirmationMenuItem
          label="Enviar correo"
          confirmationMessage={'¿Estás seguro de enviar el correo?'}
          helpText={'Enviar correo con la orden de trabajo'}
          startIcon={<EmailIcon />}
          onConfirm={async () => {
            const response = await vehicleWorkOrdersApi.sendEmail({
              workOrderNumber: workOrder.work_order_number,
              values: { company_id: currentCompany }
            });
            if (response.success) {
              showNotify(response.message || 'Correo enviado correctamente');
            } else {
              showError(response.message || 'Hubo un error al enviar el correo');
            }
          }}
        />
        <GoalMenuDialog
          onClose={menuController.handleClose}
          startIcon={<PictureAsPdf />}
          Component={PDFViewerdDialog}
          componentProps={{
            apiHandle: vehicleApi.getWorkOrderPdf,
            apiParams: { id: workOrder.work_order_number, companyId: currentCompany }
          }}
          tooltip={'Ver PDF'}
        >
          PDF
        </GoalMenuDialog>
      </GoalMenu>
    </>
  );
};

WorkOrdersMenu.propTypes = {
  workOrder: PropTypes.object.isRequired,
  handleRefresh: PropTypes.func
};
